<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>Создание ответа</VToolbarTitle>
          </VToolbar>
          <VCardText>
            <VForm ref="choiceForm">
              <VRow>
                <VCol cols="10">
                  <TextEditor
                    v-model="text"
                    label="Текст (оригинальный)"
                    class="mt-4"
                  />

                  <VCheckbox
                    v-model="right"
                    label="Верный"
                  />

                  <VBtn
                    depressed
                    color="primary"
                    @click="createChoice(testId, questionId)"
                  >
                    Создать
                  </VBtn>
                </VCol>
              </VRow>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TextEditor from '../../../TextEditor.vue';

export default {
  name: 'AnswerCreate',
  components: { TextEditor },
  inject: ['Names'],
  data() {
    return {
      text: '',
      right: false,
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
    questionId() {
      return this.$route.params.questionId;
    },
  },
  methods: {
    async createChoice(testId, questionId) {
      try {
        const {
          text, right,
        } = this;

        const payload = {
          testId,
          questionId,
          text,
          right,
        };

        const answerResponse = await this.$di.api.Talent.choiceCreate(payload);
        this.$di.notify.snackSuccess('Ответ успешно создан');

        this.$router.push({
          name: this.Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWER_EDIT,
          params: {
            ...this.$route.params,
            answerId: answerResponse.id,
          },
        });
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    clear() {
      this.$refs.choiceForm.reset();
    },
  },
};
</script>
